@import "variables";
@import "fonts";

html {
  position: relative;
  width: 100%;
}

.main_Container {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // overflow-x: hidden;
}

body {
  position: relative !important;
  font-family: var(--regular);
  font-style: normal;
  font-size: 15px;
  // background-color: var(--white);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}

p {
  font-size: 15px;
  font-family: var(--regular);
  // padding-bottom: 15px;
}

*::-moz-selection {
  background: var(--themeColor);
  color: #fff;
  text-shadow: none;
}

*::-moz-selection {
  background: var(--themeColor) none repeat scroll 0 0;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: var(--themeColor);
  color: #fff;
  text-shadow: none;
}

.content-main-container {
  margin: 30px 50px;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
}


.percentage-content {
  width: 42%;
}

.percentage {
  width: 55%;
}

.percentage-field {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.box-wrapper {
  padding: 25px 15px;
  // display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#e6e6e6), to(#f9f9f9));
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

input,
select {
  height: 40px;
  width: 250px;
  padding: 5px;
  // margin: 0 8px;
  border: none;
  outline: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.unit-converter {
  display: flex;
  flex-direction: column;
  gap: 8px;

}

.claculate-btn {
  height: 36px;
  width: 85px;
  padding: 5px;
  margin: 0 5px;
  // color: var(--whiteColor);
  // border: 1px solid var(--darkBlue);
  outline: none;
  // background-color: var(--whiteColor);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.button-select {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.Faq-content {
  margin: 0 50px;
  padding-bottom: 30px;
}

.box-wrapper>div a {
  color: black;
  text-transform: capitalize;
  font-size: 16px;
  margin: 6px;
  padding: 6px 12px;
  border: 1px solid white;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.box-wrapper>div a:hover {
  background-color: black;
  color: white !important;
}

.active {
  background-color: black;
  color: white !important;
}

.active {
  background-color: black;
  color: white;
}



@media (max-width: 991px) {
  .content-main-container {
    flex-wrap: wrap;
  }


  .percentage {
    width: 100% !important;
    margin: auto;
    text-align: center;
  }

  .percentage-content {
    width: 100%;
  }

  .Faq-content {
    height: auto;
    overflow-y: auto;
  }

}

@media (max-width: 768px) {
  .content-main-container {
    flex-wrap: wrap;
    margin: 10px;
    padding: 30px 0;
  }

  .box-wrapper {
    padding: 20px 0px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .heading-font {
    font-size: 25px !important;
  }

  .percentage-field {
    flex-direction: column;
  }

  .unit-converter {
    width: 100%;
    padding: 15px 25px;
  }

  input,
  select {
    height: 40px;
    width: 100%;
  }

  .Faq-content {
    margin: 0 15px;
  }

}

@media (max-width: 576px) {

  .button-select {
    flex-direction: column;
  }

}


//  direction RTL 

.rtl .box-wrapper>div a,
.rtl select {
  font-family: var(--arRegular);
}

.rtl .change-Btn {
  position: fixed;
  bottom: 0;
  right: auto !important;
  left: 0;
}